import { memo } from 'react'
import isEqual from 'react-fast-compare'

import { DesktopOnlyCarousel } from './DesktopOnlyCarousel'
import {
  ResponsiveCarousel,
  ResponsiveCarouselProps,
} from './ResponsiveCarousel'

export type FireCarouselProps = ResponsiveCarouselProps & {
  isDesktopOnly?: boolean
}

export const FireCarousel = memo(
  ({ isDesktopOnly = false, ...rest }: FireCarouselProps) => {
    const Component = isDesktopOnly ? DesktopOnlyCarousel : ResponsiveCarousel
    return <Component {...rest} />
  },
  isEqual
)
