import { ReactElement } from 'react'

import {
  StyledDesktopView,
  StyledMobileView,
} from './DesktopOnlyCarousel.styles'
import {
  ResponsiveCarousel,
  ResponsiveCarouselProps,
} from './ResponsiveCarousel'

export type DesktopOnlyCarouselProps = {
  children: ReactElement | ReactElement[]
  className?: string
} & Pick<
  ResponsiveCarouselProps,
  | 'onHandleScrollCallback'
  | 'moveToRef'
  | 'showPaginationDots'
  | 'fullWidthItems'
>

export function DesktopOnlyCarousel({
  children,
  className,
  ...rest
}: DesktopOnlyCarouselProps) {
  return (
    <div className={className}>
      <StyledDesktopView>
        <ResponsiveCarousel {...rest}>{children}</ResponsiveCarousel>
      </StyledDesktopView>
      <StyledMobileView>{children}</StyledMobileView>
    </div>
  )
}
