import styled, { css } from 'styled-components'

import { Body } from '@nx/fire/typography'

export const StyledPaginationBlock = styled.div<{
  $showPagination: boolean
  $singleContainer: boolean
}>`
  ${({ $showPagination, $singleContainer }) => css`
    display: grid;
    grid-template-columns: ${$showPagination ? 'auto 1fr auto' : 'auto auto'};
    justify-content: space-between;

    ${!$singleContainer &&
    css`
      margin-top: 20px;
      margin-bottom: 11px;
      min-height: 40px;
    `}
  `}
`

export const StyledArrowButton = styled.button`
  all: unset;
  width: 38px;
  height: 38px;
  display: grid;
  place-items: center;
  border-radius: 2px;

  &:disabled {
    color: ${({ theme }) => theme.colours.grey['45']};
  }

  &:enabled {
    color: ${({ theme }) => theme.colours.primary.primary};
    cursor: pointer;

    &:hover,
    &:focus-visible {
      color: ${({ theme }) => theme.colours.secondary.primary};
    }
  }

  ${({ theme }) => theme.components?.FireCarousel?.PageArrow};
`

export const StyledIndicatorPagination = styled.ul`
  padding: 0;
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  gap: 7px;
`

export const StyledNumericPagination = styled(Body)`
  text-align: center;
  margin: auto 0;
`

export const StyledPaginationPage = styled.li`
  list-style: none;
  display: grid;
  place-items: center;
  overflow-x: hidden;
`

export const StyledPageButton = styled.button<{ $isCurrent: boolean }>`
  all: unset;
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colours.grey['60']};
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: ${({ theme }) => theme.colours.primary.black};
  }

  ${({ theme }) => theme.components?.FireCarousel?.PageButton?.root};

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      background: ${({ theme }) => theme.colours.primary.black};
      ${({ theme }) => theme.components?.FireCarousel?.PageButton?.active};
    `}
`
