import styled, { css } from 'styled-components'

export const StyledDesktopView = styled.div`
  display: none;

  ${({ theme }) => css`
    ${theme.media.up.sm} {
      display: block;
    }
  `}
`

export const StyledMobileView = styled.div`
  ${({ theme }) => css`
    ${theme.media.up.sm} {
      display: none;
    }
  `}
`
