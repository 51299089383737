import { HTMLAttributes, PropsWithChildren } from 'react'

import { StyledSection } from './fire-section.styles'

export interface SectionProps {
  bleed?: 'partial' | 'full'
  background?: 'white' | 'off-white' | 'highlight'
  verticalSpacing?: 'full' | 'top-only' | 'bottom-only' | 'none'
}

export const Section: React.FC<
  PropsWithChildren<SectionProps & HTMLAttributes<HTMLDivElement>>
> = ({ children, bleed, background, verticalSpacing, ...props }) => {
  if (!children) return null

  return (
    <StyledSection
      {...props}
      $bleed={bleed}
      $background={background}
      $verticalSpacing={verticalSpacing}
    >
      {children}
    </StyledSection>
  )
}
