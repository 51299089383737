import debounce from 'debounce'
import {
  Children,
  HTMLAttributes,
  MutableRefObject,
  ReactElement,
  UIEventHandler,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { StyledCarousel, StyledGrid } from './ResponsiveCarousel.styles'
import Pagination from './pagination'

export interface ResponsiveCarouselProps
  extends HTMLAttributes<HTMLDivElement> {
  showPaginationDots?: boolean
  fullWidthItems?: 'always' | 'mobileOnly' | 'never'
  autoScroll?: number
  alignment?: 'center' | 'baseline' | 'start'
  moveToRef?: MutableRefObject<((page: number) => void) | undefined>
  onHandleScrollCallback?: () => void
  children: ReactElement | ReactElement[]
  defaultCarouselPage?: MutableRefObject<number>
}

interface CarouselItem {
  element: ReactElement
  width: number
  childIndex: number
}

type Container = CarouselItem[]

const getWidth = (child: HTMLElement) =>
  Math.ceil(parseInt(window.getComputedStyle(child).getPropertyValue('width')))

export const ResponsiveCarousel = (props: ResponsiveCarouselProps) => (
  // Slightly hacky way to force component to 'reset' when new children are given
  // avoids issues when client side rendering the carousel with new items
  <Carousel {...props} key={new Date().valueOf()} />
)

export function isValidElementArray(
  element: unknown
): element is ReactElement[] {
  return (
    Array.isArray(element) &&
    !!element.length &&
    element.every((e) => isValidElement(e))
  )
}

const Carousel = ({
  showPaginationDots = true,
  fullWidthItems = 'never',
  alignment = 'center',
  autoScroll = 0,
  moveToRef,
  onHandleScrollCallback,
  children,
  defaultCarouselPage,
  ...props
}: ResponsiveCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null)
  const childRefs = useRef<HTMLElement[]>([])
  const containerRefs = useRef<HTMLDivElement[]>([])

  const [showPagination, setShowPagination] = useState<boolean | null>(null)
  const [containers, setContainers] = useState<Container[]>([])
  const [mouseOver, setMouseOver] = useState(false)
  const [currentContainer, setCurrentContainer] = useState<number>(
    defaultCarouselPage?.current || 1
  )

  useEffect(() => {
    if (!('scrollBehavior' in document.documentElement.style)) {
      import('smoothscroll-polyfill').then((module) => module.polyfill())
    }
  }, [])

  useEffect(() => {
    const calcContainers = debounce(() => {
      if (!carouselRef.current) {
        return
      }

      const carouselWidth = Math.ceil(carouselRef.current.offsetWidth)
      const carouselOffsetLeft = Math.ceil(carouselRef.current.offsetLeft)

      const newContainers: Container[] = []
      let container: Container = []
      let currentContainerOffetRight = carouselWidth

      childRefs.current.forEach((child, index) => {
        const childWidth = getWidth(child)
        const childOffsetLeft = Math.ceil(child.offsetLeft)
        const childOffetRight =
          childWidth + childOffsetLeft - carouselOffsetLeft

        if (childOffetRight <= currentContainerOffetRight || !index) {
          let element = null

          if (isValidElement(children)) {
            element = children
          } else if (isValidElementArray(children)) {
            element = children[index]
          }

          if (element) {
            container.push({
              element: element,
              width: childWidth,
              childIndex: index,
            })
          }
        } else {
          newContainers.push(container)

          currentContainerOffetRight = childOffsetLeft + carouselWidth

          container = [
            {
              element: (children as ReactElement[])[index],
              width: childWidth,
              childIndex: index,
            },
          ]
        }
      })

      newContainers.push(container)

      if (
        newContainers.length !== containerRefs.current.filter(Boolean).length
      ) {
        containerRefs.current = new Array(newContainers.length).fill(null)

        setContainers(newContainers)
      }
    }, 100)

    calcContainers()

    window.addEventListener('resize', calcContainers)

    return () => window.removeEventListener('resize', calcContainers)
  }, [children, showPagination])

  const movetoPage = useCallback(
    (pageNumber: number, behavior: 'smooth' | 'instant' = 'smooth') => {
      onHandleScrollCallback?.()
      carouselRef.current?.scrollTo?.({
        top: 0,
        left:
          Math.ceil(containerRefs.current?.[pageNumber - 1]?.offsetLeft) -
          Math.ceil(carouselRef.current?.offsetLeft),
        behavior,
      })
    },
    [onHandleScrollCallback]
  )

  useEffect(() => {
    if (
      defaultCarouselPage &&
      defaultCarouselPage.current > 1 &&
      !!containerRefs.current.length
    ) {
      movetoPage(defaultCarouselPage.current, 'instant')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containers]) // we only want to do this on a full component re-render and the children are different

  useEffect(() => {
    let autoScrollInterval: NodeJS.Timeout

    if (autoScroll) {
      autoScrollInterval = setInterval(() => {
        if (currentContainer === containers.length) {
          movetoPage(1)
        } else {
          movetoPage(currentContainer + 1)
        }
      }, autoScroll)

      if (mouseOver) {
        clearInterval(autoScrollInterval)
      }
    }

    return () => clearInterval(autoScrollInterval)
  }, [autoScroll, containers.length, currentContainer, mouseOver, movetoPage])

  useEffect(() => {
    if (!!carouselRef.current && !!containers.length) {
      setShowPagination(containers.length > 1)
    }
  }, [containers.length])

  if (moveToRef) {
    moveToRef.current = movetoPage
  }

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    ({ currentTarget: carousel }) => {
      const carouselScrollLeft = Math.ceil(carousel.scrollLeft)
      const carouselScrollWidth = Math.ceil(carousel.scrollWidth)
      const carouselOffsetWidth = Math.ceil(carousel.offsetWidth)
      const carouselOffsetLeft = Math.ceil(carousel.offsetLeft)

      function updatePage(pageNumber: number) {
        setCurrentContainer(pageNumber)
        if (defaultCarouselPage) defaultCarouselPage.current = pageNumber
      }

      const nearestValue = (arr: number[], val: number) =>
        arr.reduce(
          (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
          Infinity
        ) + val

      // last page detection
      if (carouselScrollLeft + carouselOffsetWidth >= carouselScrollWidth) {
        updatePage(containers.length)

        return
      }

      const offsetLefts = containerRefs.current
        .filter(Boolean)
        .map(
          (container) => Math.ceil(container.offsetLeft) - carouselOffsetLeft
        )

      const nearestContainerOffset = nearestValue(
        offsetLefts,
        carouselScrollLeft
      )

      updatePage(
        containerRefs.current.findIndex(
          (container) =>
            Math.ceil(container?.offsetLeft) ===
            nearestContainerOffset + carouselOffsetLeft
        ) + 1
      )

      onHandleScrollCallback?.()
    },
    [containers.length, onHandleScrollCallback, defaultCarouselPage]
  )

  if (!isValidElement(children) && !isValidElementArray(children)) {
    return null
  }

  return (
    <>
      <StyledCarousel
        ref={carouselRef}
        onScroll={handleScroll}
        data-testid="carousel"
        $fullWidthItems={fullWidthItems}
        onMouseOver={autoScroll ? () => setMouseOver(true) : undefined}
        onMouseOut={autoScroll ? () => setMouseOver(false) : undefined}
        {...props}
      >
        {!containers.length
          ? Children.map(children, (child, index) =>
              cloneElement(child, {
                ref: (el: HTMLElement) => el && (childRefs.current[index] = el),
                key: `child-${index}`,
                'data-testid': `child-${index}`,
              } as unknown as HTMLAttributes<HTMLElement>)
            )
          : containers.map((container, index) => (
              <StyledGrid
                key={`container-${index}`}
                ref={(el) => {
                  if (el) {
                    containerRefs.current[index] = el
                  }
                }}
                data-testid={`container-${index + 1}`}
                {...(index + 1 === containers.length
                  ? { 'data-container-last': true }
                  : {})}
                $fullWidthItems={fullWidthItems}
                $alignment={alignment}
              >
                {container.map(({ element, childIndex }, i) =>
                  cloneElement(element, {
                    key: `element-${index}-${i}`,
                    ref: (el: HTMLElement) =>
                      (childRefs.current[childIndex] = el),
                    'data-testid': `element-${childIndex + 1}`,
                  })
                )}
              </StyledGrid>
            ))}
      </StyledCarousel>

      {showPagination && (
        <Pagination
          numOfContainers={containers.length}
          currentContainer={currentContainer}
          movetoPage={movetoPage}
          showPagination={showPaginationDots}
        />
      )}
    </>
  )
}
