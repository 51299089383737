import debounce from 'debounce'
import { useEffect, useRef, useState } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@nx/fire/assets'
import VisuallyHidden from '@nx/fire/visually-hidden'

import {
  StyledArrowButton,
  StyledIndicatorPagination,
  StyledNumericPagination,
  StyledPageButton,
  StyledPaginationBlock,
  StyledPaginationPage,
} from './pagination-styles'

interface PaginationProps {
  numOfContainers: number
  currentContainer: number
  movetoPage: (i: number) => void
  showPagination: boolean
}

const Pagination = ({
  numOfContainers,
  currentContainer,
  movetoPage,
  showPagination,
}: PaginationProps) => {
  const paginationRef = useRef<HTMLDivElement>(null)
  const indicatorWidth = 15
  const combinedPaginationButtonWidth = 150
  const [showNumericPagination, setShowNumericPagination] = useState(false)

  useEffect(() => {
    const checkPageWidth = debounce(() => {
      if (paginationRef.current) {
        const clientWidth = Math.ceil(paginationRef.current.clientWidth)

        if (clientWidth) {
          const totalIndicatorWidth = indicatorWidth * numOfContainers
          setShowNumericPagination(
            totalIndicatorWidth + combinedPaginationButtonWidth > clientWidth
          )
        }
      }
    }, 100)

    window.addEventListener('resize', checkPageWidth)
    checkPageWidth()

    return () => window.removeEventListener('resize', checkPageWidth)
  }, [numOfContainers])

  return (
    <StyledPaginationBlock
      ref={paginationRef}
      $singleContainer={numOfContainers === 1}
      $showPagination={showPagination}
    >
      <>
        <StyledArrowButton
          disabled={currentContainer === 1}
          onClick={() => movetoPage(currentContainer - 1)}
        >
          <ChevronLeftIcon width="20" height="20" title="Previous page" />
        </StyledArrowButton>

        {showPagination &&
          (showNumericPagination ? (
            <StyledNumericPagination as="div">
              {currentContainer} / {numOfContainers}
            </StyledNumericPagination>
          ) : (
            <StyledIndicatorPagination>
              {[...Array(numOfContainers).keys()].map((i) => (
                <StyledPaginationPage key={`pagination-${i}`}>
                  <StyledPageButton
                    $isCurrent={currentContainer === i + 1}
                    onClick={() => movetoPage(i + 1)}
                    aria-pressed={currentContainer === i + 1}
                    aria-current={
                      currentContainer === i + 1 ? 'page' : undefined
                    }
                  >
                    <VisuallyHidden>Page {i + 1}</VisuallyHidden>
                  </StyledPageButton>
                </StyledPaginationPage>
              ))}
            </StyledIndicatorPagination>
          ))}

        <StyledArrowButton
          disabled={currentContainer === numOfContainers}
          onClick={() => movetoPage(currentContainer + 1)}
        >
          <ChevronRightIcon width="20" height="20" title="Next page" />
        </StyledArrowButton>
      </>
    </StyledPaginationBlock>
  )
}

export default Pagination
