import { logger } from '@nx/utils/logger'

export function domainBuilder(domain: string, path: string) {
  try {
    path = new URL(`${path}/`, domain).toString()
  } catch {
    logger.trace(
      { domain, path },
      `domainBuilder cannot add domain to path => ${domain} + ${path}`
    )
    logger.info({ domain, path }, `domainBuilder cannot add domain to path`)
  }
  return path
}
