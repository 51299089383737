import styled, { css } from 'styled-components'

export const StyledGrid = styled.div<{
  $fullWidthItems: 'always' | 'mobileOnly' | 'never'
  $alignment?: 'center' | 'baseline' | 'start'
}>`
  ${({ theme, $fullWidthItems, $alignment = 'center' }) => css`
    display: grid;
    align-items: ${$alignment};
    grid-auto-flow: column;
    grid-auto-columns: ${$fullWidthItems === 'never'
      ? 'min-content'
      : undefined};
    gap: var(--gap, 20px);

    ${theme.media.up.md} {
      grid-auto-columns: ${$fullWidthItems === 'always'
        ? undefined
        : 'min-content'};
    }

    > * {
      width: ${$fullWidthItems === 'never' ? undefined : '100%'};

      ${theme.media.up.md} {
        width: ${$fullWidthItems === 'always' ? '100%' : 'inherit'};
      }
    }
  `}
`

export const StyledCarousel = styled(StyledGrid)<{
  $fullWidthItems: 'always' | 'mobileOnly' | 'never'
}>`
  ${({ theme, $fullWidthItems }) => css`
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow: scroll hidden;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior-x: contain;
    align-items: normal;
    width: 100%;
    grid-auto-columns: ${$fullWidthItems === 'never' ? undefined : '100%'};

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      display: block;
    }

    > * {
      width: ${$fullWidthItems === 'never' ? 'min-content' : '100%'};
      scroll-snap-align: start;
    }

    ${theme.media.up.md} {
      grid-auto-columns: ${$fullWidthItems === 'always'
        ? '100%'
        : 'min-content'};

      > * {
        width: ${$fullWidthItems === 'always' ? '100%' : 'min-content'};
      }
    }
  `}
`
