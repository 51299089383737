import slugify from 'slugify'

export function seoName(str: string): string {
  str = str.normalize('NFKD').replaceAll('\u2044', '/')

  return slugify(str, {
    strict: true,
    lower: true,
  })
}
